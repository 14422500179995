// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://www.chantsocial.com";

const config = {
  title: "Chant Social",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "Chant Social Inc.",
  description: "Chant Social Description",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  image:
    "https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,f_auto,q_auto,w_350/v1645536821/website/theme_primary_color_logo.png", // Path to your image you placed in the 'static' folder
  logoBlue:
    "https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto,h_110/v1645536821/website/theme_primary_color_logo.png",
  postThumbnail:
    "https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,f_auto,q_auto/v1645530940/website/banner_resized.png",
  twitterUsername: "@SufiMeditation_",
  blogDesktopBanner:
    "//cdn.bannersnack.com/banners/bdzm2unx4/embed/index.html?userId=42465324&t=1610719219",
  blogMobileBanner:
    "//cdn.bannersnack.com/banners/bu9sgifn7/embed/index.html?userId=42465324&t=1610571847",
  airtableTables: [],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: [
    "slug",
    "title",
    "description",
    "headline",
    "published",
    "shareOnTwitter",
    "imageUrl",
    "shareOnWhatsapp",
    "shareOnSms",
  ],
};

module.exports = config;
