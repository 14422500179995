/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import {
  TextAlignJustified,
  Cross,
  ChevronDown,
  ChevronUp,
  ChevronRight,
} from "akar-icons";

import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import AboutUs from "../../../static/img/menu-icons/about-us.inline.svg";
import Blog from "../../../static/img/menu-icons/blog.inline.svg";

import config from "../../config";

const { logoBlue } = config;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState("none");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown("remote");
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = "auto";
  };

  return (
    <Micro>
      <header className="remote-header">
        <div className="menu-container">
          <div className="navigation">
            <figure className="logo">
              <Link onClick={handleLinkClick} href="/" title="Chant Social">
                <h3 className="inner" style={{ paddingTop: "0.5rem" }}>
                  <strong>Chant Social</strong>
                </h3>
                {/* <img loading="lazy" src={logoBlue} alt="SufiMeditation" /> */}
              </Link>
            </figure>

            <menu className="menu" role="navigation">
              <Hamburger
                className="responsive-toggle"
                setHamburgerStatus={setHamburgerStatus}
                hamburgerOpen={hamburgerOpen}
              >
                <ul className="main-menu">
                  <li>
                    <span>
                      <Link className="menu-link" href="/">
                        Apps
                      </Link>
                    </span>
                  </li>

                  {/* <li>
                    <span>
                      <Link className="menu-link" href="/share">
                        Share
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link className="menu-link" href="/about-us">
                        About Us
                      </Link>
                    </span>
                  </li> */}

                  <li>
                    <span>
                      {/* <Link
                        className="menu-link"
                        href="https://zikirapp.upvoty.com/"
                      >
                        Resources
                      </Link> */}
                    </span>
                  </li>

                  {/* <li>
                    <span>
                      <Link className="menu-link" href="/blog">
                        Blog
                      </Link>
                    </span>
                  </li> */}

                  {/* <li>
                    <DropDown
                      title="Resources"
                      activeDropdown={activeDropdown}
                      setDropdown={setDropdown}
                      isMobile={isMobile}
                    >
                      <div className="menu-content">
                        <div className="menu-container">
                          <div className="five-column-grid-container">
                            <div className="first-area menu-card border-radius-20 bg-yellow height-120px hide-on-mobile">
                              <Link
                                onClick={handleLinkClick}
                                href="https://zikirapp.upvoty.com/"
                              >
                                <section className="p-2rem w-75 outer">
                                  <h3
                                    className="inner"
                                    style={{ paddingTop: "1rem" }}
                                  >
                                    <strong>Resources</strong>
                                  </h3>
                                  <hr className="mt-2rem inner" />
                                  <p className="mt-2rem mute inner">
                                    SufiMeditation is here to help
                                  </p>
                                  <p className="mt-3rem inner">
                                    <strong>Learn more</strong>
                                    <ChevronRight
                                      strokeWidth={4}
                                      size={12}
                                      style={{
                                        marginLeft: "0.3rem",
                                        marginBottom: "-0.1rem",
                                      }}
                                    />
                                  </p>
                                </section>
                              </Link>
                            </div>
                            <div className="second-area menu-card border-radius-20 bg-purple height-120px">
                              <Link onClick={handleLinkClick} href="/about-us">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>About Us</strong>
                                    </h3>
                                    <p className="mute margin-0">
                                      Meet our global team
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <AboutUs
                                      style={{
                                        height: "127px",
                                        marginBottom: "-20px",
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="fifth-area menu-card border-radius-20 bg-orange height-120px">
                              <Link onClick={handleLinkClick} href="/blog">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>Blog</strong>
                                    </h3>
                                    <p className="mute margin-0">
                                      Read sufi meditation articles
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <Blog
                                      style={{
                                        height: "127px",
                                        marginBottom: "-20px",
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropDown>
                  </li> */}
                </ul>
              </Hamburger>
            </menu>

            <aside className="actions text-center">
              {/* <Link
                className="menu-link cta-button"
                target="_blank"
                href="https://buy.stripe.com/8wM7vvgfk5Dv6oE28b"
              >
                <span className="my-1rem" style={{ fontSize: "14px" }}>
                  DONATE
                </span>
              </Link> */}
            </aside>
          </div>
        </div>
      </header>
    </Micro>
  );
};

const DropDown = (props) => {
  const { title, activeDropdown, setDropdown, isMobile, children } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title && activeDropdown === title) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeDropdown, title]);

  return (
    <div
      onMouseLeave={() => {
        if (!isMobile) {
          setTimeout(setDropdown("close"), 1000);
        }

        document.body.style.overflow = "auto";
      }}
      onMouseEnter={() => !isMobile && setDropdown(title)}
      role="button"
      tabIndex={0}
    >
      <Link
        className="menu-link"
        onClick={() => {
          if (open) {
            if (isMobile) {
              setDropdown("close");
            }
            document.body.style.overflow = "auto";
          } else {
            if (isMobile) {
              setDropdown(title);
            }
            document.body.style.overflow = "scroll";
          }
        }}
      >
        <span>{title}</span>
        {open ? (
          <ChevronUp
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: "-1rem", paddingTop: "0.6rem" }}
          />
        ) : (
          <ChevronDown
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: "-1rem", paddingTop: "0.6rem" }}
          />
        )}
      </Link>
      <div style={{ display: activeDropdown === title ? "block" : "none" }}>
        {children}
      </div>
    </div>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = "auto";
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = "auto";
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = "hidden";
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: "0.5rem" }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: "0.5rem 0 0.5rem 0.7rem" }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? "block" : "none",
          margin: "none",
          padding: "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
