import * as React from "react";
import moment from "moment";
import Link from "../Link/Link";
import Micro from "../../layouts/Micro";
import packageJson from "../../../package.json";

import "./footer.scss";

const Footer = () => {
  const buildDate = Date.now();

  return (
    <Micro>
      <footer className="footer micro">
        <div className="container">
          <div className="grid-4">
            <div className="align-vertical">
              <div>
                {/* <img
                  loading="lazy"
                  height="60"
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto,w_110/v1645533819/website/white_logo.png"
                  alt={`Example Company Inc. v${packageJson.version} build time: ${buildDate}`}
                /> */}

                <div className="padding-top-1rem card-neumorphic-dark padding-1rem">
                  <h6>Chant Social System</h6>
                  <p>
                    chantsocial.com is an all-in-one chant that powers live
                    operations.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h5 className="text-white fw-600">Site</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/terms"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/privacy"
                  >
                    Privacy Policy
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href={`/daily/verses/${moment().format("YYYYMMDD")}`}
                  >
                    Daily Verse
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href={`/daily/dhikr/${moment().format("YYYYMMDD")}`}
                  >
                    Daily Dhikr
                  </Link>
                </li> */}
              </ul>
            </div>

            <div>
              <h5 className="title">Guides</h5>
            </div>
          </div>

          <hr />

          <div className="grid-3 padding-top-1rem flex flex-middle ">
            <div>
              <p className="padding-1rem">
                © ChantSocial Inc. <strong>2022</strong> All rights reserved.
              </p>
            </div>

            <div className="text-center">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/sufimeditation_"
              >
                <img
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto/v1645615289/website/twitter_r1qbd6.svg"
                  alt="Example Company Inc. Twitter Official Social Media Account"
                />
              </Link>

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/chantsocial"
              >
                <img
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/c_scale,q_auto/v1645615289/website/linkedin_sprcw8.svg"
                  alt="Example Company Inc. Linkedin Official Social Media Account"
                />
              </Link>

              {/* <Link rel="noopener noreferrer" target="_blank" href="/rss.xml">
                <img
                  src="https://res.cloudinary.com/dhikr-zikir-zikirmatik/image/upload/v1645615289/website/rt-rss-feed-icon.svg"
                  alt="SufiMeditation Blog RSS Feed"
                />
              </Link> */}
            </div>

            <div className="text-right">
              Designed in{" "}
              <span role="img" aria-label="Sun">
                ☀️
              </span>{" "}
              California &amp; Built Globally With{" "}
              <span role="img" aria-label="Heart">
                ❤️
              </span>
            </div>
          </div>
        </div>
      </footer>
    </Micro>
  );
};

export default Footer;
